var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-1"},[_c('error-dialog',{attrs:{"message":"Error! Try again.","showDialog":_vm.searchError},on:{"update:showDialog":function($event){_vm.searchError=$event},"update:show-dialog":function($event){_vm.searchError=$event}}}),_c('hierarchy-tree-filter',{ref:"filters",attrs:{"results":_vm.entities,"totalResults":_vm.numberOfChildren,"refresh":_vm.refresh}}),_c('v-card',[_c('v-data-table',{staticClass:"scrollable-table__large table-header_align",attrs:{"fixed-header":"","headers":_vm.selectedHeaders,"items":_vm.entities,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.numberOfChildren,"item-key":"id","single-select":true,"hide-default-footer":_vm.numberOfChildren == 0,"footer-props":{
        'items-per-page-options': _vm.rowsPerPage,
        disablePagination: _vm.loading,
        disableItemsPerPage: _vm.loading,
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.selectRow,"dblclick:row":_vm.openRow},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.id}})]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'HierarchyTree',
            params: {
              hierarchy: _vm.hierarchyTag.address,
              id: item.id
            }
          },"target":"_blank","rel":"noopener"},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.modelName",fn:function(ref){
          var item = ref.item;
return [(item.type.toLowerCase() == 'sleeve')?_c('td',[_vm._v(" "+_vm._s(_vm.getModelNameForSleeve(item))+" ")]):_c('td',[_vm._v(_vm._s(item.modelName))])]}},{key:"item.modelId",fn:function(ref){
          var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.modelId}})]}},{key:"item.managerId",fn:function(ref){
          var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.managerId}})]}},{key:"item.billingStartDate",fn:function(ref){
          var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.billingStartDate}})]}},{key:"item.billingEndDate",fn:function(ref){
          var item = ref.item;
return [_c('date-cell',{attrs:{"value":item.billingEndDate}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":_vm.colorTheme.alert}},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }